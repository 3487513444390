import React from 'react';
import styled from 'styled-components';

const BadgeWrapper = styled.div`
  position: relative;
  padding: 6px 10px;

  &:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 7px;
    padding: 2px;
    background: linear-gradient(135deg, #00ffff, #ff00ff, #ffff00) border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
  }
`;

const BadgeContent = styled.div`
  font-weight: 700;
  position: relative;
  z-index: 1;
`;

export default ({ children }: { children: React.ReactNode }) => {
  return (
    <BadgeWrapper>
      <BadgeContent>{children}</BadgeContent>
    </BadgeWrapper>
  );
}
